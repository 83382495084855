<template>
  <table class="table-container" width="100%" role="table" aria-label="Destinations">

    <!-- cart:products:head START -->
    <thead>
      <tr class="flex-table header" role="rowgroup">
        <th
          class="flex-row first"
          v-html="$t('terminal.cart.products.product')"
          role="columnheader"
        ></th>
        <th
          class="flex-row"
          v-html="$t('terminal.cart.products.unitprice')"
          role="columnheader"
        ></th>
        <th
          class="flex-row"
          v-html="$t('terminal.cart.products.total')"
          role="columnheader"
        ></th>
        <th
          class="flex-row flex-row-quantity"
          role="columnheader"
          v-html="$t('terminal.cart.products.quantity')"
        ></th>
      </tr>
    </thead>
    <!-- cart:products:head END -->

    <!-- cart:products:product START -->
    <transition-group
      name="dialog-fade"
      tag="tbody"
      :class="[
        'products',
        { 'no-products' : products && products.length === 0 },
      ]"
    >
      <product
        v-for="product in cart"
        :key="product.id"
        :product="product"
        :increase="increase"
        :decrease="decrease"
        :priceFormat="priceFormat"
      />
    </transition-group>
    <!-- cart:products:product END -->
  </table>
</template>

<script>
export default {
  name: 'cart-products',
  props: {
    products: {
      type: Array,
    },

    increase: {
      type: Function,
      default: () => null,
    },

    decrease: {
      type: Function,
      default: () => null,
    },

    cart: {
      type: Array,
    },
  },
  components: {
    Product: () => import('./Product.vue'),
  },
  methods: {
    priceFormat(price) {
      let activeLang = 'de-DE';
      if (localStorage.getItem('boxLang') === 'en_US') {
        activeLang = 'en-US';
      }
      return new Intl.NumberFormat(
        activeLang,
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        },
      ).format(price);
    },
  },
};
</script>
